import { useApolloClient } from '@apollo/client'
import { OptionProps } from '@atlaskit/select'
import {
  MultiValueProps,
  SingleValueProps,
} from '@atlaskit/select/dist/types/types'
import Tooltip from '@atlaskit/tooltip'
import { useMemo } from 'react'

import { AIRTABLE_LEADS_TABLE_URL } from '../../constants'
import {
  AirtableLeadFragment,
  BasicAirtableLeadFragment,
  BasicAirtableLeadFragmentDoc,
  namedOperations,
  useGetAirtableLeadByLeadRecordIdQuery,
} from '../../graphql'

import { MultiValueLabelSpan, StyledOption } from './styled'

const useBasicLeadRecord = (
  leadRecordId: string | null | undefined,
): BasicAirtableLeadFragment | null => {
  const client = useApolloClient()

  const cached = useMemo(
    () =>
      leadRecordId &&
      client.readFragment<BasicAirtableLeadFragment>({
        id: `AirtableLead:${leadRecordId}`,
        fragmentName: namedOperations.Fragment.BasicAirtableLead,
        fragment: BasicAirtableLeadFragmentDoc,
      }),
    [client, leadRecordId],
  )

  const { data } = useGetAirtableLeadByLeadRecordIdQuery({
    variables: { leadRecordId: leadRecordId! },
    skip: Boolean(!leadRecordId || cached),
  })

  return cached || data?.leadRecord || null
}

export const Option = <IsMulti extends boolean>({
  innerRef,
  data: lead,
  innerProps,
  isFocused,
}: OptionProps<AirtableLeadFragment, IsMulti>) => {
  return (
    <StyledOption ref={innerRef} {...innerProps} isFocused={isFocused}>
      <strong>{lead.name}</strong>
      <br />
      <span>
        {'Next step: '}
        {lead.nextStep}
      </span>
    </StyledOption>
  )
}

export const SingleValue = ({
  innerProps,
  data: lead,
}: SingleValueProps<AirtableLeadFragment>) => {
  const basicLeadRecord = useBasicLeadRecord(lead.id)
  const leadName = basicLeadRecord?.name || '-------------------'

  return <span {...innerProps}>{leadName}</span>
}

export const MultiValueLabel = ({
  innerProps,
  data: lead,
}: MultiValueProps<AirtableLeadFragment>) => {
  const basicLeadRecord = useBasicLeadRecord(lead.id)
  const leadName = basicLeadRecord?.name || '-------------------'

  return (
    <Tooltip content={'Click to open lead card'}>
      <MultiValueLabelSpan
        onClick={() => {
          window.open(`${AIRTABLE_LEADS_TABLE_URL}/${lead.id}`, '_blank')
        }}
        {...innerProps}
      >
        {leadName}
      </MultiValueLabelSpan>
    </Tooltip>
  )
}
