import Button, { ButtonProps } from '@atlaskit/button'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import {
  AIRTABLE_BASE_URL,
  AIRTABLE_CLIENTS_TABLE_ID,
  AIRTABLE_DOUBLES_TABLE_ID,
  AIRTABLE_LEADS_TABLE_ID,
} from '../../constants'

import { ReactComponent as _Icon } from './Airtable.svg'

const Icon = styled(_Icon)`
  width: 20px;
  height: 20px;
`

export const AirtableButton = (props: ButtonProps) => (
  <Button appearance={'subtle'} iconBefore={<Icon />} {...props} />
)

type AirtableRecordButtonProps = ComponentProps<typeof AirtableButton> & {
  tableId: string
  viewId?: string
  recordId?: string
}

export const AirtableLinkButton = ({
  tableId,
  viewId,
  recordId,
  ...props
}: AirtableRecordButtonProps) => (
  <AirtableButton
    target={'_blank'}
    href={`${AIRTABLE_BASE_URL}/${tableId}${viewId ? `/${viewId}` : ''}${
      recordId ? `/${recordId}` : ''
    }`}
    {...props}
  />
)

export const AirtableLeadLinkButton = (
  props: Omit<AirtableRecordButtonProps, 'tableId'>,
) => <AirtableLinkButton tableId={AIRTABLE_LEADS_TABLE_ID} {...props} />

export const AirtableClientLinkButton = (
  props: Omit<AirtableRecordButtonProps, 'tableId'>,
) => <AirtableLinkButton tableId={AIRTABLE_CLIENTS_TABLE_ID} {...props} />

export const AirtableDoubleLinkButton = (
  props: Omit<AirtableRecordButtonProps, 'tableId'>,
) => <AirtableLinkButton tableId={AIRTABLE_DOUBLES_TABLE_ID} {...props} />

export default AirtableButton
