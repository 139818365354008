import FlagIcon from '@atlaskit/icon/glyph/flag-filled'
import PageHeader from '@atlaskit/page-header'
import React, { useCallback } from 'react'

import { LoadingSpinner } from '../../../components/Spinner'
import Toggle from '../../../components/Toggle'
import {
  useListSystemFeatureFlagsQuery,
  useUpdateSystemFeatureFlagMutation,
} from '../../../graphql'
import { Page } from '../../types'

import { Outer, Row } from './styled'

const bottomBarContent = (
  <p>
    {
      'These feature flags are used to control the visibility of features at the system level. They are not workspace-specific, but rather apply to the entire system.'
    }
  </p>
)

const SystemFeatureFlags: Page = () => {
  const { data, loading: useListSystemFeatureFlagsLoading } =
    useListSystemFeatureFlagsQuery()
  const [updateSystemFeatureFlag, { loading: updateSystemFeatureFlagLoading }] =
    useUpdateSystemFeatureFlagMutation()

  const toggleFeatureFlag = useCallback(
    (flagId: string, isEnabled: boolean) => {
      updateSystemFeatureFlag({
        variables: {
          input: {
            featureFlagId: flagId,
            isEnabled,
          },
        },
      })
    },
    [updateSystemFeatureFlag],
  )

  const featureFlags = data?.list.items || []

  const loading =
    useListSystemFeatureFlagsLoading || updateSystemFeatureFlagLoading

  return (
    <Outer>
      <PageHeader bottomBar={bottomBarContent}>
        {'System-level Feature Flags'}
      </PageHeader>
      {featureFlags.map((flag) => (
        <React.Fragment key={flag.id}>
          <Row>
            <Toggle
              isChecked={flag.isEnabled}
              onChange={(e) => toggleFeatureFlag(flag.id, e.target.checked)}
            />
            <p>
              <strong>{flag.id}</strong>
              {` - ${flag.description}`}
            </p>
          </Row>
        </React.Fragment>
      ))}

      <LoadingSpinner show={loading} />
    </Outer>
  )
}

SystemFeatureFlags.path = '/system-feature-flags'
SystemFeatureFlags.navTitle = 'System Feature Flags'
SystemFeatureFlags.Icon = FlagIcon
SystemFeatureFlags.requiredOperationIds = ['Query.systemFeatureFlagsList']

export default SystemFeatureFlags
