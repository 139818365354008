import ExitOrientationIcon from '@atlaskit/icon/glyph/check-circle'
import ResetOrientationIcon from '@atlaskit/icon/glyph/check-circle-outline'
import DownloadIcon from '@atlaskit/icon/glyph/download'
import EmailLoginIcon from '@atlaskit/icon/glyph/email'
import FileIcon from '@atlaskit/icon/glyph/file'
import ArchiveIcon from '@atlaskit/icon/glyph/trash'
import PauseIcon from '@atlaskit/icon/glyph/vid-pause'
import PageHeader from '@atlaskit/page-header'
import { Classes, EditableText } from '@blueprintjs/core'
import React, { ComponentProps, useCallback } from 'react'
import styled from 'styled-components'

import { AirtableLinkButton } from '../../../components/AirtableButton'
import {
  MoreDropdownMenu,
  DropdownItemGroup,
  DropdownItem,
} from '../../../components/DropdownMenu'
import AssistantScore from '../../../components/users/AssistantScore'
import Avatar from '../../../components/users/Avatar'
import AverageFeedback from '../../../components/users/AverageFeedback'
import UploadAvatarModal from '../../../components/users/UploadAvatarModal'
import {
  AIRTABLE_CLIENTS_TABLE_ID,
  AIRTABLE_DOUBLES_TABLE_ID,
  AIRTABLE_LEADS_TABLE_ID,
} from '../../../constants'
import {
  FullUserFragment,
  useSendLoginEmailMutation,
  useUpdateUserMutation,
  useUpdateOrientationMutation,
  isAssistantable,
  isExecutivable as _isExecutivable,
  isExecutiveUser,
  useUnarchiveUserMutation,
  isAssistantUser,
} from '../../../graphql'
import useDownloadPhoto from '../../../hooks/useDownloadPhoto'
import useSwitch from '../../../lib/useSwitch'

import ArchiveUserModal from './components/ArchiveUserModal'
import ChurnExecutiveModal from './components/ChurnExecutiveModal'

const Inner = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 0 30px;

  & > *:not(:first-child) {
    margin-left: 12px;
  }
`

export const FieldsRow = styled.div`
  display: flex;

  & > div {
    flex: 0 0 auto;

    &:first-child {
      flex: 1;
    }

    & + div {
      margin-left: 16px;
    }
  }
`

const ScoringContainer = styled.div`
  margin-top: -7px;
`

interface Props extends ComponentProps<'header'> {
  user?: FullUserFragment
  skeleton?: boolean
  isDisabled?: boolean
}

const Header = ({ user, skeleton, isDisabled }: Props) => {
  const userId = user?.id || ''
  const isExecutivable = _isExecutivable(user)
  const isAssistant = isAssistantable(user)

  const [updateOrientation] = useUpdateOrientationMutation()
  const [updateUser, { loading: isUpdatingUser }] = useUpdateUserMutation()
  const { downloadPhoto } = useDownloadPhoto(
    user?.profile?.avatarUrl,
    user?.profile.displayName || user?.profile.givenName || 'avatar',
  )
  const [isShowingPhotoModal, showPhotoModal, hidePhotoModal] = useSwitch(false)

  const [isArchiveModalOpen, openArchiveModal, closeArchiveModal] =
    useSwitch(false)

  const togglePause = useCallback(() => {
    if (isExecutivable) {
      updateUser({
        variables: {
          userId,
          input: {
            isOnPause: !user?.isOnPause,
          },
        },
      })
    }
  }, [updateUser, user, userId, isExecutivable])

  const [
    sendLoginEmailMutation,
    { data: dataSendLoginEmail, loading: loadingSendLoginEmail },
  ] = useSendLoginEmailMutation()
  const sendLoginEmail = useCallback(
    () =>
      sendLoginEmailMutation({
        variables: {
          input: {
            userId,
          },
        },
      }),
    [sendLoginEmailMutation, userId],
  )

  const onSubmitGivenName = useCallback(
    (givenName: string) => {
      updateUser({
        variables: {
          userId: userId || '',
          input: {
            givenName,
          },
        },
      })
    },
    [updateUser, userId],
  )

  const onSubmitFamilyName = useCallback(
    (familyName: string) => {
      updateUser({
        variables: {
          userId: userId || '',
          input: {
            familyName,
          },
        },
      })
    },
    [updateUser, userId],
  )

  const [_unarchiveUser] = useUnarchiveUserMutation({
    variables: {
      input: {
        userId: user?.id || '',
      },
    },
  })
  const unarchiveUser = useCallback(() => {
    if (
      window.confirm(
        'Restore user? Their primary workspace will be restored too.',
      )
    ) {
      _unarchiveUser()
    }
  }, [_unarchiveUser])

  const onSubmitPhotoUrl = useCallback(
    (photoUrl: string) => {
      hidePhotoModal()
      updateUser({
        variables: {
          userId: userId || '',
          input: {
            photoUrl,
          },
        },
      })
    },
    [hidePhotoModal, updateUser, userId],
  )

  return (
    <PageHeader
      actions={
        isDisabled ? (
          <></>
        ) : (
          <FieldsRow>
            {isExecutivable && user.airtableLeadRecordId && (
              <AirtableLinkButton
                tableId={AIRTABLE_LEADS_TABLE_ID}
                recordId={user.airtableLeadRecordId}
              >
                {'Lead'}
              </AirtableLinkButton>
            )}
            {isExecutivable && user.airtableClientRecordId && (
              <AirtableLinkButton
                tableId={AIRTABLE_CLIENTS_TABLE_ID}
                recordId={user.airtableClientRecordId}
              >
                {'Client'}
              </AirtableLinkButton>
            )}
            {isAssistant && user.airtableDoubleRecordId && (
              <AirtableLinkButton
                tableId={AIRTABLE_DOUBLES_TABLE_ID}
                recordId={user.airtableDoubleRecordId}
              />
            )}
            <MoreDropdownMenu
              isLoading={loadingSendLoginEmail || isUpdatingUser}
            >
              <DropdownItemGroup>
                <DropdownItem
                  onClick={downloadPhoto}
                  isDisabled={!user?.profile?.avatarUrl}
                  elemBefore={<DownloadIcon size={'small'} label={''} />}
                >
                  {'Download picture'}
                </DropdownItem>
                {isAssistant && (
                  <DropdownItem
                    onClick={() =>
                      // open resume in new tab
                      window.open(user.resumeUrl as string, '_blank')
                    }
                    isDisabled={!user.resumeUrl}
                    elemBefore={<FileIcon size={'small'} label={''} />}
                  >
                    {'View resume'}
                  </DropdownItem>
                )}
              </DropdownItemGroup>
              <DropdownItemGroup title={'Admin'}>
                {isExecutivable && (
                  <DropdownItem
                    onClick={togglePause}
                    elemBefore={<PauseIcon size={'small'} label={''} />}
                  >
                    {user?.isOnPause ? 'Un-pause user' : 'Put user on pause'}
                  </DropdownItem>
                )}
                {isAssistant && (
                  <DropdownItem
                    onClick={() =>
                      updateOrientation({
                        variables: {
                          input: {
                            userIds: [userId],
                            reset: !!user?.orientation?.isCompleted,
                            markAsComplete: !user?.orientation?.isCompleted,
                          },
                        },
                      })
                    }
                    elemBefore={
                      user?.orientation?.isCompleted ? (
                        <ResetOrientationIcon size={'small'} label={''} />
                      ) : (
                        <ExitOrientationIcon size={'small'} label={''} />
                      )
                    }
                  >
                    {user?.orientation?.isCompleted
                      ? 'Reset orientation'
                      : 'Exit orientation'}
                  </DropdownItem>
                )}
                <DropdownItem
                  onClick={sendLoginEmail}
                  isDisabled={!!dataSendLoginEmail}
                  elemBefore={<EmailLoginIcon size={'small'} label={''} />}
                >
                  {dataSendLoginEmail
                    ? `Login link sent to ${dataSendLoginEmail.sendLoginEmail.email} 👍`
                    : 'Send email with login link'}
                </DropdownItem>
                {user?.isArchived ? (
                  <DropdownItem
                    onClick={unarchiveUser}
                    elemBefore={<ArchiveIcon size={'small'} label={''} />}
                  >
                    {'Un-archive user'}
                  </DropdownItem>
                ) : (
                  <DropdownItem
                    onClick={openArchiveModal}
                    elemBefore={<ArchiveIcon size={'small'} label={''} />}
                  >
                    {isExecutivable ? 'Churn' : 'Archive user'}
                  </DropdownItem>
                )}
              </DropdownItemGroup>
            </MoreDropdownMenu>
          </FieldsRow>
        )
      }
    >
      <Inner>
        <Avatar
          user={user}
          size={'large'}
          skeleton={skeleton}
          onClick={isDisabled ? () => null : showPhotoModal}
        />
        <EditableText
          // Force refresh the input when commited value changes
          key={user?.profile.givenName}
          className={skeleton ? Classes.SKELETON : undefined}
          placeholder={'Given name'}
          minWidth={20}
          defaultValue={user?.profile?.givenName || ''}
          onConfirm={onSubmitGivenName}
          disabled={isDisabled}
        />
        <EditableText
          key={user?.profile.familyName}
          className={skeleton ? Classes.SKELETON : undefined}
          placeholder={'Family name'}
          minWidth={20}
          defaultValue={user?.profile.familyName || ''}
          onConfirm={onSubmitFamilyName}
          disabled={isDisabled}
        />
        {isAssistantUser(user) && (
          <ScoringContainer>
            <AverageFeedback user={user} />{' '}
            <AssistantScore score={user.performanceScoreValue || undefined} />
          </ScoringContainer>
        )}
      </Inner>

      <UploadAvatarModal
        isOpen={isShowingPhotoModal}
        onClose={hidePhotoModal}
        onSubmitPhotoUrl={onSubmitPhotoUrl}
      />

      {!!user &&
        (isExecutiveUser(user) ? (
          <ChurnExecutiveModal
            isOpen={isArchiveModalOpen}
            onClose={closeArchiveModal}
            user={user}
          />
        ) : (
          <ArchiveUserModal
            isOpen={isArchiveModalOpen}
            onClose={closeArchiveModal}
            user={user}
          />
        ))}
    </PageHeader>
  )
}

export default Header
