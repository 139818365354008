import React, { useMemo } from 'react'

import { Field } from '../../../../components/form'
import UserSelect from '../../../../components/users/Select'
import {
  TeamFragment,
  UpdateTeamInput,
  useUpdateTeamMutation,
} from '../../../../graphql'
import useValues from '../../../../lib/useValues'

import { Outer, Button } from './styled'

type Props = {
  team: TeamFragment
}

const PrincipalUser = ({ team }: Props) => {
  const [formValues, { setters }] = useValues<UpdateTeamInput>(
    {
      principalUserId: team.principalUser.id,
    },
    ['principalUserId'],
  )

  const [updateTeam, { loading }] = useUpdateTeamMutation()

  const onClickSave = async () => {
    await updateTeam({
      variables: {
        teamId: team.id,
        input: {
          ...formValues,
        },
      },
    })
  }

  const teammateIds = useMemo(
    () =>
      team.workspaces
        .flatMap(({ executives }) => executives.map(({ id }) => id))
        .filter((teammateId) => teammateId !== team.principalUser.id),
    [team.principalUser.id, team.workspaces],
  )

  const isSaveDisabled = formValues.principalUserId === team.principalUser.id

  return (
    <Outer>
      <Field label={'Principal User'}>
        <UserSelect
          userIds={teammateIds}
          isClearable={false}
          isDisabled={false}
          value={
            formValues.principalUserId
              ? { id: formValues.principalUserId }
              : undefined
          }
          onChange={(user) => {
            user && setters.principalUserId?.(user.id)
          }}
        />
      </Field>
      <Button
        isDisabled={isSaveDisabled}
        appearance={'primary'}
        isLoading={loading}
        onClick={onClickSave}
      >
        {'Save'}
      </Button>
    </Outer>
  )
}

export default PrincipalUser
