import { colors } from '@atlaskit/theme'
import styled, { css } from 'styled-components'

export const Outer = styled.div`
  display: flex;
  align-items: center;

  & > *:first-child {
    flex: 1;
    margin-right: 8px;
  }
`

export const StyledOption = styled.div<{ isFocused: boolean }>`
  padding: 6px 12px;

  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: ${colors.backgroundHover};
    `}

  strong {
    font-size: 14px;
    font-weight: 400;
  }

  span {
    font-size: 9px;
    font-weight: 500;
    color: ${colors.subtleText};
    text-transform: uppercase;
  }
`

export const MultiValueLabelSpan = styled.span`
  &:hover {
    background-color: #c5cad1;
  }
`
