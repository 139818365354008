import { DatePicker } from '@atlaskit/datetime-picker'
import SectionMessage from '@atlaskit/section-message'
import { uniq } from 'lodash'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { usePauseTeamMutation, useUnpauseTeamMutation } from '../../graphql'
import useNow from '../../lib/useNow'
import Toggle from '../Toggle'
import { Field } from '../form'

import { Link, SectionMessageOuter } from './styled'

type Props = {
  teamId: string
  isPaused: boolean
  startAt?: DateTime
  endAt?: DateTime
}

const PAUSE_NOTION_GUIDELINES_URL =
  'https://www.notion.so/withdouble/Client-Account-Pause-Guidelines-3a762dc81c9f44608c186a834e4e3d3a?pvs=4'

const TeamPause = ({ teamId, isPaused, startAt, endAt }: Props) => {
  const [pauseTeam, { loading: pauseLoading }] = usePauseTeamMutation()
  const [unpauseTeam, { loading: unpauseLoading }] = useUnpauseTeamMutation()
  const now = useNow()
  const endOfNextMonth = now.plus({ months: 1 }).endOf('month')
  const pausedBillingMonths = uniq([startAt?.monthLong, endAt?.monthLong])

  const onClickPause = useCallback(() => {
    pauseTeam({
      variables: {
        input: {
          teamId,
          startAt: DateTime.now().toISO(),
          endAt: endOfNextMonth.toISO(),
        },
      },
    })
  }, [endOfNextMonth, pauseTeam, teamId])

  const onUnpauseDateChange = useCallback(
    async (date: string) => {
      await unpauseTeam({
        variables: {
          input: {
            teamId,
            endAt: DateTime.fromISO(date).setZone('America/New_York').toISO(),
          },
        },
      })
    },
    [unpauseTeam, teamId],
  )

  const onUnpauseClicked = useCallback(() => {
    unpauseTeam({
      variables: {
        input: {
          teamId,
          endAt: DateTime.now().toISO(),
        },
      },
    })
  }, [unpauseTeam, teamId])

  return (
    <SectionMessageOuter>
      <SectionMessage
        appearance={isPaused ? 'information' : 'success'}
        title={
          isPaused
            ? `Team's billing is currently paused`
            : `Team's billing is active`
        }
      >
        <p>
          {'Please review the '}
          <Link
            href={PAUSE_NOTION_GUIDELINES_URL}
            target={'_blank'}
            rel={'noreferrer'}
          >
            {'Client Account Pause Guidelines'}
          </Link>
          {' before proceeding as pausing affects billing.'}
        </p>
        <Field label={'Pause Billing'}>
          <Toggle
            label={'Pause Team'}
            onChange={isPaused ? onUnpauseClicked : onClickPause}
            isChecked={isPaused}
            isDisabled={pauseLoading || unpauseLoading}
          />
        </Field>
        {isPaused && (
          <>
            <Field label={'Unpause Date'}>
              <DatePicker
                onChange={onUnpauseDateChange}
                placeholder={'Select a date to unpause'}
                value={endAt?.toISODate()}
                minDate={now.plus({ days: 1 }).startOf('day').toISO()}
                maxDate={endOfNextMonth.toISODate()}
                isDisabled={unpauseLoading}
              />
            </Field>
            <p>{`Client is paused and has optimal billing for ${pausedBillingMonths.join(
              ' and ',
            )}.`}</p>
          </>
        )}
      </SectionMessage>
    </SectionMessageOuter>
  )
}

export default TeamPause
