import { DateTime } from 'luxon'
import React, { ComponentProps, useMemo } from 'react'

import ErrorBanner from '../../../../components/ErrorBanner'
import { LoadingSpinner } from '../../../../components/Spinner'
import History from '../../../../components/billing/History'
import PricingPackage from '../../../../components/billing/PricingPackage'
import StripeCustomer from '../../../../components/billing/StripeCustomer'
import TeamPause from '../../../../components/billing/TeamPause'
import {
  useCreateStripeCustomerMutation,
  useGetTeamBillingInfoQuery,
  useGetTeamInvoicingHistoryListQuery,
  useSelectEntityPricingMutation,
} from '../../../../graphql'
import useSwitch from '../../../../lib/useSwitch'
import { PricingDrawer } from '../../../finances/Pricings/Pricing'

interface Props extends ComponentProps<'div'> {
  teamId: string
}

const Billing = ({ teamId }: Props) => {
  const [isSelectingPricing, startSelectingPricing, stopSelectingPricing] =
    useSwitch(false)

  const {
    data,
    loading: getTeamBillingLoading,
    error: getTeamBillingError,
    //refetch: refetchTeamBillingInfo,
  } = useGetTeamBillingInfoQuery({
    variables: { id: teamId },
  })

  const {
    data: invoicingHistoryData,
    loading: invoicingHistoryLoading,
    error: errorInvoicingHistory,
    refetch: refetchInvoicingHistory,
  } = useGetTeamInvoicingHistoryListQuery({
    variables: { teamId },
  })

  const [
    selectEntityPricing,
    { loading: entityPricingLoading, error: entityPricingError },
  ] = useSelectEntityPricingMutation({
    onCompleted: () => {
      stopSelectingPricing()
      refetchInvoicingHistory()
    },
  })

  const [
    createStripeCustomer,
    { loading: createStripeCustomerLoading, error: createStripeCustomerError },
  ] = useCreateStripeCustomerMutation({
    variables: { input: { entityId: teamId } },
  })

  const team = data?.team

  const { billingPauseStatus } = team || {}
  const pauseStartAt = billingPauseStatus?.startAt
    ? DateTime.fromJSDate(new Date(billingPauseStatus.startAt))
    : undefined
  const pauseEndAt = billingPauseStatus?.endAt
    ? DateTime.fromJSDate(new Date(billingPauseStatus.endAt))
    : undefined

  const stripeCustomer = team?.stripeCustomer

  const currentTeamPricing = team?.currentPricing
  const pricingSelections = team?.pricingSelections || []

  const error =
    getTeamBillingError || entityPricingError || createStripeCustomerError
  const loading =
    getTeamBillingLoading ||
    entityPricingLoading ||
    createStripeCustomerLoading ||
    invoicingHistoryLoading

  const startDate = useMemo(() => {
    const createdAt = invoicingHistoryData?.team.createdAt
    return createdAt ? new Date(createdAt) : undefined
  }, [invoicingHistoryData?.team.createdAt])

  return (
    <>
      <ErrorBanner error={error} />

      <TeamPause
        teamId={teamId}
        isPaused={!!billingPauseStatus}
        startAt={pauseStartAt}
        endAt={pauseEndAt}
      />

      <StripeCustomer
        stripeCustomer={stripeCustomer}
        loading={loading}
        createStripeCustomer={createStripeCustomer}
      />

      <PricingPackage
        entity={team}
        currentEntityPricing={currentTeamPricing}
        isSelectingPricing={isSelectingPricing}
        startSelectingPricing={startSelectingPricing}
        stopSelectingPricing={stopSelectingPricing}
        selectEntityPricing={selectEntityPricing}
      />

      <History
        pricingSelections={pricingSelections}
        pricingSelectionsLoading={loading}
        invoicedCycleItems={
          invoicingHistoryData?.invoicingHistoryList.items || []
        }
        invoicingHistoryError={errorInvoicingHistory}
        invoicingHistoryLoading={invoicingHistoryLoading}
        isArchived={invoicingHistoryData?.team.isArchived || false}
        startDate={startDate}
      />

      <LoadingSpinner show={loading} />
      <PricingDrawer />
    </>
  )
}

export default Billing
