import { Checkbox as AkCheckbox } from '@atlaskit/checkbox'
import { Field as AkField, HelperMessage } from '@atlaskit/form'
import AkSelect, {
  RadioSelect as AkRadioSelect,
  PopupSelect as AkPopupSelect,
  CreatableSelect as AkCreatableSelect,
} from '@atlaskit/select'
import AkTextArea from '@atlaskit/textarea'
import debounce from 'lodash/debounce'
import uniq from 'lodash/uniq'
import React, {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

export * from './TextField'
export { default as SearchTextField } from './SearchTextField'
export { default as ImageUrlField } from './ImageUrlField'
export { default as ColorPicker } from './ColorPicker'
export { default as SaveButtons } from './SaveButtons'
export { default as EmailAddressField } from './EmailAddressField'

type FieldProps = Partial<
  Omit<ComponentProps<typeof AkField>, 'name' | 'children'>
> & {
  children: ReactNode
  helperMessage?: JSX.Element[] | JSX.Element | string
}

export const Field = ({ children, helperMessage, ...props }: FieldProps) => (
  <AkField name={'--'} {...props}>
    {() => (
      <>
        {children}
        {!!helperMessage && <HelperMessage children={helperMessage} />}
      </>
    )}
  </AkField>
)

export const FieldsRow = styled.div`
  display: flex;

  & > div {
    flex: 1 1;

    & + div {
      margin-left: 16px;
    }
  }

  & + & {
    margin-top: 4px;
  }
`

type TextAreaProps = Omit<ComponentProps<typeof AkTextArea>, 'onChange'> &
  Partial<Pick<ComponentProps<typeof AkTextArea>, 'onChange'>> & {
    onChangeValue?: (value: string) => void
  }

export const TextArea = ({
  onChangeValue,
  onChange,
  ...props
}: TextAreaProps) => {
  const onFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChangeValue?.(event.currentTarget.value)
      onChange?.(event)
    },
    [onChange, onChangeValue],
  )

  return <AkTextArea onChange={onFieldChange} {...props} />
}

type DebouncedTextAreaProps = TextAreaProps & {
  debounceMs: number
}

export const DebouncedTextArea = ({
  debounceMs,
  value: _value,
  onChangeValue: _onChangeValue,
  ...props
}: DebouncedTextAreaProps) => {
  const [value, setValue] = useState(_value)
  const localUpdate = useRef<boolean>(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateExternalValue = useCallback(
    debounce((newValue: string) => {
      localUpdate.current = false
      _onChangeValue?.(newValue)
    }, debounceMs),
    [_onChangeValue, localUpdate],
  )

  const updateValue = useCallback(
    (newValue: string) => {
      localUpdate.current = true
      setValue(newValue)
      updateExternalValue(newValue)
    },
    [setValue, updateExternalValue, localUpdate],
  )

  useEffect(() => {
    if (!localUpdate.current) {
      setValue(_value)
    }
  }, [_value, setValue, localUpdate])

  return <TextArea value={value} onChangeValue={updateValue} {...props} />
}

type CheckboxProps = Partial<ComponentProps<typeof AkCheckbox>> & {
  onChangeValue?: (value: boolean) => void
}

export const Checkbox = ({
  onChangeValue,
  onChange,
  ...props
}: CheckboxProps) => {
  const onFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, analyticsEvent: any) => {
      onChangeValue?.(event.currentTarget.checked)
      onChange?.(event, analyticsEvent)
    },
    [onChange, onChangeValue],
  )

  return <AkCheckbox onChange={onFieldChange} {...props} />
}

type SelectProps<V = string> = Partial<ComponentProps<typeof AkSelect>> & {
  onChangeValue?: (value: V) => void
  isRadio?: boolean
  isCreatable?: boolean
}

export function Select<V = string>({
  onChangeValue,
  onChange,
  isRadio,
  isMulti,
  isCreatable,
  value,
  ...props
}: SelectProps<V>) {
  const onFieldChange = useCallback(
    (patch, action) => {
      onChangeValue?.(
        (isMulti
          ? patch?.map((p: { value: string }) => p.value) || []
          : patch?.value) || null,
      )
      onChange?.(patch, action)
    },
    [onChange, onChangeValue, isMulti],
  )

  const onCreateOption = useCallback(
    (newOption: V) => {
      onChangeValue?.(
        // @ts-ignore
        (isMulti
          ? uniq(
              // @ts-ignore
              (value?.map((p: { value: V }) => p.value) || [])
                .concat(newOption)
                .filter(Boolean),
            )
          : newOption) || null,
      )
    },
    [onChangeValue, isMulti, value],
  )

  const Select = isCreatable
    ? AkCreatableSelect
    : isRadio
    ? AkRadioSelect
    : AkSelect

  return (
    <Select
      // @ts-ignore
      value={value}
      onChange={onFieldChange}
      // @ts-expect-error
      isMulti={isMulti}
      onCreateOption={isCreatable ? onCreateOption : undefined}
      {...props}
    />
  )
}

type PopupSelectProps<OptionValue = string> = Partial<
  Omit<
    ComponentProps<typeof AkPopupSelect>,
    'getOptionLabel' | 'options' | 'value' | 'target'
  >
> & {
  onChangeValue?: (value: OptionValue) => void
  options: OptionValue[]
  value?: OptionValue
  getOptionLabel?: (option: OptionValue) => string | undefined | void
  target?: (options: {
    ref: React.RefObject<HTMLElement>
    isOpen: boolean
  }) => ReactNode
}

export function PopupSelect<OptionValue = string>({
  onChangeValue,
  onChange,
  options,
  value,
  getOptionLabel,
  ...props
}: PopupSelectProps<OptionValue>) {
  const mapOption = useCallback(
    (option: OptionValue) => ({
      value: option,
      label: getOptionLabel?.(option) || String(option),
    }),
    [getOptionLabel],
  )

  const onFieldChange = useCallback(
    (patch, action) => {
      onChangeValue?.(patch?.value || null)
      onChange?.(patch, action)
    },
    [onChange, onChangeValue],
  )

  return (
    <AkPopupSelect
      onChange={onFieldChange}
      options={options.map(mapOption)}
      value={value ? mapOption(value) : undefined}
      {...props}
    />
  )
}
