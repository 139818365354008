import { useApolloClient } from '@apollo/client'
import Button from '@atlaskit/button'
import OpenIcon from '@atlaskit/icon/glyph/shortcut'
import React, { useCallback } from 'react'

import { AIRTABLE_LEADS_TABLE_URL } from '../../constants'
import {
  ListAirtableLeadsDocument,
  ListAirtableLeadsQuery,
  ListAirtableLeadsQueryVariables,
  AirtableLeadFragment,
} from '../../graphql'
import { notFalsy } from '../../lib/utils'
import AsyncSelectWithDebounce, {
  SelectProps,
  ValueType,
} from '../AsyncSelectWithDebounce'

import { Option, SingleValue, MultiValueLabel } from './SelectComponents'
import { Outer } from './styled'

type Props<IsMulti extends boolean> = SelectProps<
  AirtableLeadFragment,
  IsMulti
> & {
  ignoreLeadIds?: string[]
  inNextSteps?: string[]
  isClient?: boolean
}

const AirtableLeadSelect = <IsMulti extends boolean = false>({
  value,
  onChange,
  ignoreLeadIds,
  inNextSteps,
  isClient,
  ...props
}: Props<IsMulti>) => {
  const client = useApolloClient()

  const loadOptions = useCallback(
    (inputValue: string): Promise<AirtableLeadFragment[]> => {
      return new Promise(async (resolve) => {
        try {
          const { data } = await client.query<
            ListAirtableLeadsQuery,
            ListAirtableLeadsQueryVariables
          >({
            query: ListAirtableLeadsDocument,
            variables: {
              nameContains: inputValue || null,
              inNextSteps: inputValue ? null : inNextSteps,
              isClient,
            },
          })

          let leads = data.list.items.filter(notFalsy)
          if (ignoreLeadIds?.length) {
            leads = leads.filter((lead) => !ignoreLeadIds.includes(lead.id))
          }

          resolve(leads)
        } catch (error) {
          resolve([])
        }
      })
    },
    [client, ignoreLeadIds, inNextSteps, isClient],
  )

  return (
    <AsyncSelectWithDebounce<AirtableLeadFragment, IsMulti>
      minimumInputLength={3}
      defaultOptions
      loadOptions={loadOptions}
      getOptionValue={(lead) => lead.id}
      placeholder={'Search Airtable lead by name'}
      onChange={onChange}
      noOptionsMessage={() => 'No match found'}
      value={value}
      components={{
        Option,
        SingleValue,
        MultiValueLabel,
      }}
      {...props}
    />
  )
}

export const SingleAirtableLeadSelect = ({
  value,
  onChangeValue,
  onChangeValueId,
  ...props
}: Props<false> & {
  onChangeValueId?: (newValue: AirtableLeadFragment['id'] | null) => void
  onChangeValue?: (newValue: AirtableLeadFragment | null) => void
}) => {
  const onSelect = useCallback(
    (newValue: ValueType<AirtableLeadFragment, false>) => {
      onChangeValueId?.(newValue?.id || null)
      onChangeValue?.(newValue || null)
    },
    [onChangeValue, onChangeValueId],
  )

  return (
    <Outer>
      <AirtableLeadSelect {...props} isMulti={false} onChange={onSelect} />
      {!!value && (
        <Button
          href={`${AIRTABLE_LEADS_TABLE_URL}/${value?.id}`}
          target={'_blank'}
          iconBefore={<OpenIcon label={''} />}
          appearance={'subtle'}
        />
      )}
    </Outer>
  )
}

export const MultiAirtableLeadSelect = ({
  onChangeValues,
  onChangeValueIds,
  ...props
}: Props<true> & {
  onChangeValueIds?: (newValue: AirtableLeadFragment['id'][] | null) => void
  onChangeValues?: (newValue: AirtableLeadFragment[] | null) => void
}) => {
  const onSelect = useCallback(
    (value: ValueType<AirtableLeadFragment, true>) => {
      onChangeValueIds?.(value?.map((v) => v.id) || null)
      // 💩 mapping as itself to get around value's readonly type
      onChangeValues?.(value?.map((v) => v) || null)
    },
    [onChangeValues, onChangeValueIds],
  )

  return (
    <Outer>
      <AirtableLeadSelect {...props} isMulti onChange={onSelect} />
    </Outer>
  )
}
