import AsyncSelect from '@atlaskit/select/AsyncSelect'
import debounce from 'debounce-promise'
import React, { ComponentProps, useCallback } from 'react'

export type { SelectProps, ValueType } from '@atlaskit/select'

type Props<T, IsMulti extends boolean> = ComponentProps<
  typeof AsyncSelect<T, IsMulti>
> & {
  loadOptions: (inputValue: string) => Promise<T[]>
  noOptionsMessage?: (obj: { inputValue: string }) => string | null
  minimumInputLength?: number
}

/**
 * Debounce wrapper for AsyncSelect
 */
const AsyncSelectWithDebounce = <
  T extends { id: string },
  IsMulti extends boolean = false,
>({
  loadOptions,
  noOptionsMessage = () => 'No options',
  minimumInputLength = 1,
  ...otherProps
}: Props<T, IsMulti>) => {
  // using a special debounce function that returns a promise to the next function
  // (details: https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917)
  const debouncedLoadOptions = debounce(loadOptions, 300)

  const handleLoadOptions = useCallback(
    (search: string) => {
      // don't load options if search is too short, takes too long to load options
      if (search.length < minimumInputLength) {
        return Promise.resolve([])
      }

      return debouncedLoadOptions(search)
    },
    [debouncedLoadOptions, minimumInputLength],
  )

  return (
    <AsyncSelect<T, IsMulti>
      loadOptions={handleLoadOptions}
      noOptionsMessage={({ inputValue }) => {
        if (!inputValue || inputValue.length < minimumInputLength) {
          return `Type at least ${minimumInputLength} characters to search`
        }

        return noOptionsMessage({ inputValue })
      }}
      {...otherProps}
    />
  )
}

export default AsyncSelectWithDebounce
