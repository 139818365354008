import DownloadIcon from '@atlaskit/icon/glyph/download'
import ArchiveIcon from '@atlaskit/icon/glyph/trash'
import PageHeader from '@atlaskit/page-header'
import { Classes, EditableText } from '@blueprintjs/core'
import React, { ComponentProps, useCallback } from 'react'

import Badge from '../../../../components/Badge'
import {
  MoreDropdownMenu,
  DropdownItemGroup,
  DropdownItem,
} from '../../../../components/DropdownMenu'
import Avatar from '../../../../components/teams/Avatar'
import UploadAvatarModal from '../../../../components/users/UploadAvatarModal'
import {
  TeamFragment,
  useUnarchiveTeamMutation,
  useUpdateTeamMutation,
} from '../../../../graphql/generated/hooks'
import useDownloadPhoto from '../../../../hooks/useDownloadPhoto'
import useSwitch from '../../../../lib/useSwitch'

import { FieldsRow, Inner } from './styled'

interface Props extends ComponentProps<'header'> {
  team?: TeamFragment
  skeleton?: boolean
  isDisabled?: boolean
}

const Header = ({ team, skeleton, isDisabled }: Props) => {
  const teamId = team?.id || ''
  const [updateTeam, { loading: isUpdatingTeam }] = useUpdateTeamMutation()
  const [unarchiveTeam, { loading: isUnarchivingTeam }] =
    useUnarchiveTeamMutation()
  const { downloadPhoto } = useDownloadPhoto(
    team?.iconUrl,
    team?.name || 'avatar',
  )
  const [isShowingPhotoModal, showPhotoModal, hidePhotoModal] = useSwitch(false)

  const onSubmitTeamName = useCallback(
    (name: string) => {
      updateTeam({
        variables: {
          teamId,
          input: {
            name,
          },
        },
      })
    },
    [teamId, updateTeam],
  )

  const onUnarchiveTeam = useCallback(() => {
    unarchiveTeam({
      variables: {
        input: {
          teamId,
        },
      },
    })
  }, [teamId, unarchiveTeam])

  const onSubmitPhotoUrl = useCallback(
    (iconUrl: string) => {
      hidePhotoModal()
      updateTeam({
        variables: {
          teamId,
          input: {
            iconUrl,
          },
        },
      })
    },
    [hidePhotoModal, teamId, updateTeam],
  )

  if (!team) {
    return null
  }

  return (
    <PageHeader
      actions={
        isDisabled ? (
          <></>
        ) : (
          <FieldsRow>
            <MoreDropdownMenu>
              <DropdownItemGroup>
                <DropdownItem
                  onClick={downloadPhoto}
                  isDisabled={!team?.iconUrl}
                  elemBefore={<DownloadIcon size={'small'} label={''} />}
                >
                  {'Download picture'}
                </DropdownItem>
              </DropdownItemGroup>
              {team?.isArchived && (
                <DropdownItemGroup title={'Admin'}>
                  <DropdownItem
                    onClick={onUnarchiveTeam}
                    elemBefore={<ArchiveIcon size={'small'} label={''} />}
                    isDisabled={isUnarchivingTeam}
                  >
                    {'Un-archive team'}
                  </DropdownItem>
                </DropdownItemGroup>
              )}
            </MoreDropdownMenu>
          </FieldsRow>
        )
      }
    >
      <Inner>
        <Avatar
          team={team}
          size={'large'}
          skeleton={skeleton}
          onClick={isDisabled ? () => null : showPhotoModal}
        />
        <EditableText
          // Force refresh the input when commited value changes
          key={team.name}
          className={skeleton ? Classes.SKELETON : undefined}
          placeholder={'Team name'}
          minWidth={20}
          defaultValue={team.name || ''}
          onConfirm={onSubmitTeamName}
          disabled={isDisabled}
        />
        {team.billingPauseStatus && <Badge isLight text={'Billing Paused'} />}
      </Inner>

      <UploadAvatarModal
        isOpen={isShowingPhotoModal}
        onClose={hidePhotoModal}
        onSubmitPhotoUrl={onSubmitPhotoUrl}
        isLoadingProp={isUpdatingTeam}
      />
    </PageHeader>
  )
}

export default Header
