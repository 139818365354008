import { LoadingButton } from '@atlaskit/button'
import InfoIcon from '@atlaskit/icon/glyph/info'
// @ts-ignore
import { Group } from '@atlaskit/navigation-next'
import groupBy from 'lodash/groupBy'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import BooleanSelect from '../../../../components/BooleanSelect'
import { ErrorEmptyState } from '../../../../components/EmptyState'
import PageHeader from '../../../../components/PageHeader'
import { LoadingSpinner } from '../../../../components/Spinner'
import Toggle, { ToggleOuter } from '../../../../components/Toggle'
import { Field } from '../../../../components/form'
import ListContainer from '../../../../components/list/ListContainer'
import ListOuter from '../../../../components/list/ListOuter'
import PageHeaderOuter from '../../../../components/list/PageHeaderOuter'
import {
  MatchingProposalReply,
  useListMatchingsQuery,
  useListSystemFeatureFlagsQuery,
} from '../../../../graphql'
import useSwitch from '../../../../lib/useSwitch'
import useValues from '../../../../lib/useValues'
import CreateMatchingModal from '../CreateMatchingModal'
import EducationalModal from '../EducationalModal'
import {
  sortMatchings,
  Filter,
  DEFAULT_FILTER,
  hasActiveFilter,
} from '../utils'

import Item from './Item'

const Outer = styled(ListContainer)`
  min-width: 280px;
  max-width: 350px;
`

enum GroupKey {
  PROPOSALS_ACCEPTED = 'PROPOSALS_ACCEPTED',
  PROPOSALS_DECLINED = 'PROPOSALS_DECLINED',
  PROPOSALS_SENT = 'PROPOSALS_SENT',
  NO_PROPOSALS = 'NO_PROPOSALS',
}

const CREATE_MATCHING_FROM_COCKPIT_FEATURE_FLAG_ID = 'createMatchingFromCockpit'

const MatchingsList = () => {
  const [eduModalIsOpen, openEduModal, closeEduModal] = useSwitch(false)
  const [
    isCreateMatchingModalOpen,
    openCreateMatchingModal,
    closeCreateMatchingModal,
  ] = useSwitch(false)

  const { data: systemFeatureFlagData } = useListSystemFeatureFlagsQuery()
  const createMatchingFromCockpitIsEnabled = useMemo(
    () =>
      (
        systemFeatureFlagData?.list.items.find(
          (flag) => flag.id === CREATE_MATCHING_FROM_COCKPIT_FEATURE_FLAG_ID,
        ) || { isEnabled: false }
      ).isEnabled,
    [systemFeatureFlagData?.list.items],
  )

  const [searchTerm, setSearchTerm] = useState<string | null | undefined>()
  const [filter, { setters }] = useValues<Filter>(DEFAULT_FILTER, [
    'isArchived',
    'isCompleted',
    'isHiddenFromCatalog',
    'isTransition',
    'needsMatching',
  ])

  const { data, loading, error, refetch } = useListMatchingsQuery({
    variables: {
      isArchived: filter.isArchived,
      isCompleted: filter.isCompleted,
      isHiddenFromCatalog: filter.isHiddenFromCatalog,
      isTransition: filter.isTransition,
      isPrincipalLead: filter.isPrincipalLead,
    },
  })

  const pendingMatchings = useMemo(() => {
    return (data?.list.items || [])
      .filter(({ hasToBeMatched }) => hasToBeMatched || !filter.needsMatching)
      .filter((matching) => {
        if (!searchTerm?.trim()) {
          return true
        }

        return (
          matching.id === searchTerm ||
          matching.profiles.some(
            ({ id, name }) =>
              id === searchTerm ||
              name?.toLowerCase().includes(searchTerm.trim().toLowerCase()),
          )
        )
      })
  }, [data?.list.items, filter.needsMatching, searchTerm])

  const groups = useMemo(() => {
    return groupBy(
      pendingMatchings,
      ({ proposals }): GroupKey =>
        proposals?.some(({ reply }) => reply === MatchingProposalReply.YES)
          ? GroupKey.PROPOSALS_ACCEPTED
          : proposals?.some(
              ({ sentAt, reply }) =>
                sentAt &&
                (!reply || reply === MatchingProposalReply.NEED_MORE_INFO),
            )
          ? GroupKey.PROPOSALS_SENT
          : proposals?.some(({ reply }) => reply === MatchingProposalReply.NO)
          ? GroupKey.PROPOSALS_DECLINED
          : GroupKey.NO_PROPOSALS,
    )
  }, [pendingMatchings])

  const createActions = useMemo(
    () =>
      createMatchingFromCockpitIsEnabled
        ? [
            {
              label: 'Matching',
              onClick: openCreateMatchingModal,
            },
          ]
        : [],
    [createMatchingFromCockpitIsEnabled, openCreateMatchingModal],
  )

  return (
    <Outer>
      <PageHeaderOuter>
        <PageHeader
          searchTerm={searchTerm}
          onChangeSearchTerm={setSearchTerm}
          hasActiveFilter={hasActiveFilter(filter)}
          actions={
            <LoadingButton
              iconAfter={<InfoIcon label={''} />}
              appearance={'subtle'}
              onClick={openEduModal}
            />
          }
          createActions={createActions}
          filtersContent={() => (
            <>
              <Field label={''}>
                <ToggleOuter>
                  <Toggle
                    isChecked={filter.needsMatching}
                    onChange={() => {
                      setters.needsMatching(!filter.needsMatching)
                    }}
                  />
                  {'Needs matching'}
                </ToggleOuter>
              </Field>
              <Field label={'Visibility in catalog'}>
                <BooleanSelect
                  yesLabel={'Excluded from catalog'}
                  noLabel={'Visible in catalog'}
                  value={filter.isHiddenFromCatalog}
                  onChangeValue={setters.isHiddenFromCatalog!}
                  isClearable
                />
              </Field>
              <Field label={'Transition'}>
                <BooleanSelect
                  value={filter.isTransition}
                  onChangeValue={setters.isTransition!}
                  isClearable
                />
              </Field>
              <Field label={'Completed'}>
                <BooleanSelect
                  value={filter.isCompleted}
                  onChangeValue={setters.isCompleted!}
                  isClearable
                />
              </Field>
              <Field label={'Archived'}>
                <BooleanSelect
                  value={filter.isArchived}
                  onChangeValue={setters.isArchived!}
                  isClearable
                />
              </Field>
            </>
          )}
        >
          {'Matchings'}
        </PageHeader>
      </PageHeaderOuter>
      <ListOuter>
        {!!error && <ErrorEmptyState error={error} />}
        {filter.isArchived !== false || filter.isCompleted !== false ? (
          // Do not group
          pendingMatchings.map(
            (matching) =>
              matching && <Item key={matching.id} matching={matching} />,
          )
        ) : (
          // Groups
          <>
            {!!groups[GroupKey.PROPOSALS_ACCEPTED]?.length && (
              <Group heading={'Assistant accepted'}>
                {sortMatchings(groups[GroupKey.PROPOSALS_ACCEPTED]).map(
                  (matching) =>
                    matching && <Item key={matching.id} matching={matching} />,
                )}
              </Group>
            )}
            {!!groups[GroupKey.PROPOSALS_DECLINED]?.length && (
              <Group heading={'Assistant declined'}>
                {sortMatchings(groups[GroupKey.PROPOSALS_DECLINED]).map(
                  (matching) =>
                    matching && <Item key={matching.id} matching={matching} />,
                )}
              </Group>
            )}
            {!!groups[GroupKey.PROPOSALS_SENT]?.length && (
              <Group heading={'Waiting for assistant reply'}>
                {sortMatchings(groups[GroupKey.PROPOSALS_SENT]).map(
                  (matching) =>
                    matching && <Item key={matching.id} matching={matching} />,
                )}
              </Group>
            )}
            {!!groups[GroupKey.NO_PROPOSALS]?.length && (
              <Group heading={'Not sent to any assistant'}>
                {sortMatchings(groups[GroupKey.NO_PROPOSALS]).map(
                  (matching) =>
                    matching && <Item key={matching.id} matching={matching} />,
                )}
              </Group>
            )}
          </>
        )}
      </ListOuter>
      {!!loading && <LoadingSpinner show />}

      <EducationalModal isOpen={eduModalIsOpen} onClose={closeEduModal} />
      <CreateMatchingModal
        isOpen={isCreateMatchingModalOpen}
        onClose={closeCreateMatchingModal}
        refetchMatchingsList={refetch}
      />
    </Outer>
  )
}

export default MatchingsList
