import { InvoicedCycleFragment } from '../../graphql'
import { notFalsy } from '../../lib/utils'
import Section from '../Section'
import Tabs from '../Tabs'

import InvoicingHistory from './InvoicingHistory'
import PricingPackageHistory, {
  Props as PricingPackageHistoryProps,
} from './PricingPackageHistory'

type Props = PricingPackageHistoryProps & {
  invoicedCycleItems?: InvoicedCycleFragment[]
  invoicingHistoryLoading?: boolean
  invoicingHistoryError?: Error
  startDate?: Date
  isArchived: boolean
}

const History = ({
  pricingSelections,
  pricingSelectionsLoading,
  invoicedCycleItems,
  invoicingHistoryLoading,
  invoicingHistoryError,
  startDate,
  isArchived,
}: Props) => {
  const tabs = [
    {
      label: 'Invoicing',
      content: (
        <InvoicingHistory
          invoicedCycleItems={invoicedCycleItems}
          loading={invoicingHistoryLoading}
          error={invoicingHistoryError}
          startDate={startDate}
          isArchived={isArchived}
        />
      ),
    },
    {
      label: 'Package',
      content: (
        <PricingPackageHistory
          pricingSelections={pricingSelections}
          pricingSelectionsLoading={pricingSelectionsLoading}
        />
      ),
    },
  ].filter(notFalsy)

  if (pricingSelections.length === 0) {
    return null
  }

  return (
    <Section title={'History'}>
      <Tabs leftTabs={tabs} />
    </Section>
  )
}

export default History
