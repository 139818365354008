import Table from '@atlaskit/dynamic-table'
import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { InvoicedCycleFragment } from '../../graphql'
import Badge, { Tint } from '../Badge'

import { PackageRow, Timestamp } from './styled'

function formatDate(date: null | undefined | string | Date): string {
  if (!date) {
    return ''
  }

  return DateTime.fromJSDate(new Date(date), {
    zone: 'America/New_York',
  }).toLocaleString({
    year: '2-digit',
    month: 'short',
    day: 'numeric',
  })
}

const TABLE_HEAD = {
  cells: [
    {
      content: 'Billing Cycle',
      shouldTruncate: false,
    },
    {
      content: 'Package',
      shouldTruncate: false,
    },
    {
      content: 'Status',
      shouldTruncate: false,
    },
  ],
}

export type InvoicingHistoryProps = {
  invoicedCycleItems?: InvoicedCycleFragment[]
  loading?: boolean
  error?: Error
  startDate?: Date
  isArchived: boolean
}
const getTintColor = (status: string) => {
  switch (status) {
    case 'NEXT':
      return 'blue'
    case 'PAID':
      return 'green'
    case 'OPEN':
      return 'red'
    case 'CURRENT':
      return 'yellow'
    default:
      return 'gray'
  }
}
const InvoicingHistory = ({
  invoicedCycleItems: items,
  loading,
  error,
  startDate,
  isArchived,
}: InvoicingHistoryProps) => {
  const { pathname } = useLocation()

  const isFirstBillingCycle = useCallback(
    (cycleStartDate, cycleEndDate, index) => {
      if (!startDate) {
        const lastItemIndex = items?.length ? items.length - 1 : 0
        return lastItemIndex === index
      }
      // if the start date is between the cycle start and end date, it is the first cycle
      return startDate >= cycleStartDate && startDate <= cycleEndDate
    },
    [startDate, items],
  )

  const getStatusText = useCallback(
    (status: string) => {
      if (isArchived && status === 'CURRENT') {
        return 'ARCHIVED MONTH'
      }
      return status
    },
    [isArchived],
  )

  if (error || !items || items.length === 0) {
    return <span>{'Error loading invoicing history'}</span>
  }

  return (
    <Table
      isLoading={loading}
      head={TABLE_HEAD}
      rows={items.map(
        ({ cycleEndDate, cycleStartDate, invoicedPricing, status }, index) => ({
          key: String(cycleStartDate),
          cells: [
            {
              content: (
                <>
                  <Timestamp title={String(cycleStartDate)}>
                    {formatDate(cycleStartDate)}
                  </Timestamp>
                  {' - '}
                  <Timestamp title={cycleEndDate && String(cycleEndDate)}>
                    {formatDate(cycleEndDate)}
                  </Timestamp>
                </>
              ),
            },
            {
              content: isFirstBillingCycle(
                cycleStartDate,
                cycleEndDate,
                index,
              ) ? (
                <PackageRow to={'#'}>
                  <strong>{'M1 No Minimum'}</strong>
                </PackageRow>
              ) : invoicedPricing ? (
                <PackageRow
                  to={`${pathname}/pricings/${invoicedPricing.pricing.id}`}
                >
                  <strong>{invoicedPricing.pricing.ref}</strong>
                </PackageRow>
              ) : (
                <PackageRow to={'#'}> </PackageRow>
              ),
            },
            {
              content: (
                <Badge
                  text={getStatusText(status)}
                  tint={getTintColor(status) as Tint}
                />
              ),
            },
          ],
        }),
      )}
    />
  )
}

export default InvoicingHistory
